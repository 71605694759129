<template>
    <div class="body-container">
        <BannerSection title="Prestamos con garantia de auto en Edomex" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p class="mb-0">
                        ¿Estás buscando <b>préstamos en el Estado de México</b> que te permitan obtener el dinero que necesitas de forma rápida y sencilla? 
                    </p>
                    <p>
                        No busques más, aquí te contamos cómo puedes acceder a ellos, qué opciones tienes a tu disposición y cómo puedes solicitar tu préstamo hoy mismo sin complicaciones.
                    </p>
                    <p>
                        En el Estado de México, existen diversas alternativas de financiación, tanto a través de entidades financieras como de sistemas de empeño. A continuación, te ayudaremos a entender cuál es la mejor opción para ti.
                    </p>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Qué son los préstamos en el Estado de México?
                        </h2>
                    </span>

                    <p>
                        Los préstamos en Estado de México son opciones de crédito que están disponibles para los residentes del Estado de México. Estas soluciones financieras permiten a las personas obtener dinero de manera rápida para cubrir gastos imprevistos, consolidar deudas o realizar proyectos personales sin necesidad de largos trámites bancarios.
                    </p>
                    <p>
                        <b>El Estado de México</b>  cuenta con diversas instituciones financieras que ofrecen estos préstamos, muchos de ellos con procesos completamente en línea, lo que facilita el acceso a fondos en poco tiempo.
                    </p>
                   
                    <br>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Tipos de préstamos disponibles:
                        </h2>
                    </span>
                    <p>
                        En Endomex, puedes encontrar varios tipos de préstamos, entre los que destacan:
                    </p>
                    <br>
                    <ul>
                        <li>
                            <p>
                                <b>Préstamos personales:</b> Ideales para quienes necesitan una cantidad específica de dinero sin poner ningún bien como garantía.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Préstamos con garantía:</b> <b>Ibancar</b>, ofrece una solución innovadora y conveniente para obtener un crédito utilizando tu coche como garantía, pero sin perder su propiedad ni dejar de utilizarlo.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Préstamos inmediatos:</b> Opciones rápidas que permiten acceder a pequeñas cantidades de dinero en cuestión de horas.
                            </p>
                        </li>
                        <br>
                    </ul>
                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">¿Por qué elegir un préstamo en el Estado de México?</h2>
                    </span>
                    <p>
                        Solicitar un <b>préstamo en el Estado de México</b> tiene varias ventajas que lo convierten en una excelente opción para cubrir tus necesidades financieras. Algunas de las razones por las que optar por estos préstamos incluyen:
                    </p>
                    <br>
                    <ul>
                        <li>
                            <p>
                                <b>Acceso rápido a efectivo:</b> Los procesos son ágiles y puedes recibir el dinero en 24 horas.
                            </p>
                        </li>

                        <li>
                            <p>
                                <b>Múltiples opciones:</b> Puedes elegir entre una gran variedad de productos financieros, dependiendo de tus necesidades y capacidad de pago.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Trámites sencillos:</b> Muchas de las opciones de préstamos se gestionan 100% en línea, lo que te ahorra tiempo y desplazamientos.
                            </p>
                        </li>
                    </ul>
                    <br>
                </div>
            </div>
        </div>


        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">Cómo solicitar un préstamo en el Estado de México paso a paso</h2>
                    </span>
                    <p>
                        Solicitar un préstamo en el Estado de México es muy sencillo. A continuación, te mostramos los pasos básicos que debes seguir para conseguir tu financiación:
                    </p>
                    <br>
                    <ol>
                        <li>
                            <p>
                                <b>Selecciona el tipo de préstamo:</b> Decide el tipo de préstamo que mejor se ajuste a tu situación personal.
                            </p>    
                        </li>
                        <li>
                            <p>
                                <b>Completa la solicitud en línea:</b> Muchas instituciones financieras ofrecen formularios online donde deberás ingresar tus datos personales, el monto que deseas y tu situación financiera.
                            </p>
                        </li>
                        <li>

                            <p>
                                <b>Revisa la propuesta:</b> Tras la solicitud, recibirás una propuesta con los términos del préstamo, incluyendo la tasa de interés, plazos y condiciones de pago.
                            </p>
                        </li>
                        <li>

                            <p>
                                <b>Firma el contrato:</b> Si aceptas la propuesta, el último paso es firmar el contrato. Todo este proceso puede hacerse digitalmente.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Recibe el dinero:</b> Una vez firmado, recibirás el dinero en tu cuenta bancaria en un plazo que puede ir desde unas pocas horas hasta 48 horas.
                            </p>
                        </li>
                    </ol>
                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">¿Por qué la propuesta de Ibancar es asequible para el Estado de México y Morelos?</h2>
                    </span>
                    <p>
                        La propuesta de <b>Ibancar</b> es especialmente asequible para quienes residen en el Estado de México y Morelos porque combina las ventajas de un préstamo rápido con la flexibilidad de mantener el uso de tu auto, diferenciándose de los empeños tradicionales. 
                    </p>
                    <p>
                        A diferencia de los empeños en Morelos, donde debes entregar tu vehículo como garantía y perder su uso hasta pagar la deuda, con <b>Ibancar</b> puedes seguir manejándolo mientras obtienes el financiamiento que necesitas. Además, el proceso es 100% en línea y sin cambiar la titularidad del coche, lo que lo convierte en una opción más práctica y accesible para estas regiones. Más detalles en <router-link to="/prestamo-garantia-auto">este enlace</router-link>.
                    </p>

                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">¿Cuánto tarda en aprobarse un préstamo en el Estado de México?</h2>
                    </span>
                    <p>
                        El tiempo de aprobación varía según la entidad, pero en general, puedes recibir la respuesta y el dinero en tu cuenta en 24 horas.
                    </p>

                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">¿Necesito un aval para solicitar un préstamo en el Estado de México?</h2>
                    </span>
                    <p>
                        Si tienes mal historial de crédito, lo más probable es que necesites de una garantía. como tu auto. Esto hace que puedas acceder a un préstamo casi seguro. Además, en algunos casos, como el de Ibancar, no necesitas entregar tu coche, por lo que sigues disponiendo de él. 
                    </p>
                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">¿Qué pasa si no puedo pagar el préstamo a tiempo?</h2>
                    </span>
                    <p>
                        Es importante que te comuniques con la entidad financiera si tienes dificultades para cumplir con los pagos. Algunas entidades pueden ofrecer prórrogas o reestructuración de la deuda, pero en caso de impago continuado, podrías enfrentarte a sanciones o cargos adicionales.
                    </p>
                    <p>
                        Solicitar <b>préstamos en el Estado de México</b> es una opción rápida y efectiva para resolver tus necesidades financieras, con la ventaja de que puedes elegir entre diversas alternativas que se ajustan a tus circunstancias. Antes de tomar una decisión, asegúrate de comparar las ofertas y elegir la que mejor se adapte a ti.
                    </p>
                    <br>
                </div>
            </div>
        </div>
     
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosDeDineroRapido',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Prestamos con garantia de auto en Edomex',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Accede a préstamos con garantía de auto en el Edomex de forma rápida y segura. Obtén tu dinero en 24 horas, sigue utilizando tu coche y resuelve tus necesidades financieras sin complicaciones. ¡Solicita 100% en línea ahora!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos en endomex  - empeños en morelos'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamos-garantia-auto-edomex', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>